<template>
  <div class="mawa-soon" style="background-color: transparent">
    <img class="responsive" alt="Mawa logo" src="../assets/logo.png" />
    <div>
      <h1>Soon</h1>
      <h4> : Initial 02</h4>
    </div>
  </div>
</template>

<script>
export default {
  name: "SoonPage",
  created() {},
  data() {
    return {};
  },
  props: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.responsive {
  width: 30%;
  height: auto;
}
@media (max-width: 991px) {
  .responsive {
    width: 50%;
    height: auto;
  }
}
</style>
